import React, { useContext} from 'react';
import { Context } from '../Provider';
import Layout from '../Component/Layout';
import BreadCrubs from '../Component/BreadCrubs';
import Content from '../Component/Content';
import QuickForm from '../Component/QuickForm';
import PrivacyPolicy from '../Component/PrivacyPolicy';

function Privacy() {
    const [state, dispatch] = useContext(Context);
    return (
        <Layout>
           <BreadCrubs title="privacy-policy"/>
           <PrivacyPolicy/>
           { state.quickform ? <QuickForm/>:'' }
        </Layout>
    )
}

export default Privacy
