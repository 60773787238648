import React from 'react'

function Content() {
    return (
        <div>
            <div className="col12 sm4 md8 flexCC pt4 pb4">
                <div className="wrapper">
                    <div className="col12 sm4 md8 flex sflexDCC aboutContent">
                        <div className="col12 sm4 md8 pa">
                            <h1 className='f3 mb'>Best Game Development Training Institute in Kerala</h1>
                            <h4 className='f1 mf mb2'>Online/Offline Training Division</h4>
                            <p className='taj mb'>BigBoy School of Gaming Pvt.Ltd is a game development training institute with years of experience. Our precursors Infy Academy (2019) and Smartway Solutions (2016) paved way for us in finding the right talents for the gaming industry.  The prime focus of the BigBoy School of Gaming is to provide the best game developer training with our well-structured courses. </p>
                            <p className='taj mb'>Unity is one of the most popular game engines used in developing games for multiple platforms. Our Unity game developer program helps students to understand the workflow of the engine and other aspects that helps in creating exciting games for all.</p>
                        </div>
                        <div className="col12 sm4 md8 pa flex aboutImg">
                            <img src="./about-image.png" alt="" />
                        </div>
                    </div> 
                    <div className="col12 sm4 md12 pt2 pb2 flexC c6b pa2 br mt2 sflexDC">
                        <div className="flexDCC col4 sm4 md4 ">
                            <img src="./hire-chair.png" alt="" />
                        </div>
                        <div className="flexDCL col8 sm4 md4">
                            <h3 className='col12 sm4 md8 f3 mf'>We’re Hiring!</h3> 
                            <h5 className='col12 sm4 md8 taj mb mf mt'>Are You an Expert in Unity Game Engine? We are always in search of the industry best trainers. Share your resume with us.</h5>
                            <span><a className='c5t bf' href="mailto:info@bigboyschool.com"><i class="fa-solid fa-envelope"></i> info@bigboyschool.com</a> </span>
                        </div>
                        
                    </div>                   
                </div>
            </div>
        </div>
    )
}

export default Content
