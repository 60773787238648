import React,{ useContext } from 'react';
import { Context } from '../Provider';
import BannerHyperLink from './BannerHyperLink';

function Banner() {
    const [state, dispatch] = useContext(Context);   
    return (
        <div className='col12 sm4 md8 c1b Banner rel flexCC autoH'>
            <div className="col12 flexCC">
                <div className="wrapper flexCC sflexDC">
                    <div className="col7 sm4 c7t pa2">
                        <h3 className='mf mb f3'>The Best Game Development Training Center</h3>
                        <p className='mb2 f1'>BigBoy School of Gaming offers industry demanding Unity game development courses with expert trainers.</p>
                        <button className='btn2 br0 bn mf f3 c6b c7t' onClick={()=>{dispatch('popupForm')}}><i class="fas fa-envelope-open-text"></i><span className='ml'>Contact</span></button>
                    </div>
                    <div className="col5 sm4 bannerImg">
                        <img src="../bigboy-banner1.png" alt="" />    
                    </div>
                </div>
            </div>                
           <BannerHyperLink/>
        </div>
    )
}

export default Banner
