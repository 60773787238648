import React,{ useContext } from 'react';
import { Context } from '../Provider';

function Welcome() {
    const [state, dispatch] = useContext(Context);   
    return (
        <div className='col12 sm4 md8 welcome flexCC pa4 pb4'>
            <div className="flexDCC wrapper">
                <img className='mb2' src="../graduation.png" alt="" />
                <h2 className='c7t f3 mf tac mb2 col10 sm4 md8'>Training @ BigBoy School of Gaming</h2>
                <h5 className='c7t f3 mf tac mb2 col6 sm4 md8'>We are one of the leading game developer training institutes with years of experience in training and promoting Unity developers for the gaming industry. Unity engine is widely used in different fields apart from the gaming industry like automotive, architecture, engineering etc.</h5>
                <p className='c7t f1 mf tac mb2 col7 sm4 md8'>Our course structure is designed to train students in every aspect of the Unity engine and enable them to develop exciting games using the engine.</p>
                <button className='btn2 br0 bn mf f3 c6b c7t' onClick={()=>{dispatch('popupForm')}}>
                <i class="fas fa-user-tie"></i> <span className='ml'>Contact Us</span></button>
            </div>
        </div>
    )
}

export default Welcome
