import React, { useContext} from 'react';
import { Context } from '../Provider';


function Course(props) {  
    const [state, dispatch] = useContext(Context);    
    return (
        <div className='col12 sm4 md8 Course pt8 pb8 flexCC'>
            <div className="wrapper">
                {!props.noTitle ? <h3 className='TitleHead mb4 mf f3 col12 tac'>Our Courses</h3> : ''}                
                <ul className='CourseDetails col12 sm4 md8 prl4'>
                    <li className='pb'>
                        <img src="../junior-unity-game-developer.jpg" className='col12 sm4 md4'/>
                        <div className="col12 sm4 md8 pa prl2 c7t flexDCC">
                            <div className="col12 flex mb mt">
                                <h5 className='f3 mf c2t col12'>Junior: Unity Game Developer</h5>
                            </div>
                            <p className='col12 sT'>
                            Learn the working of the Unity Game Engine and its components that helps in developing a 2D game.
                            </p>
                            <div className="col12 flexDC">      
                                <div className="col12 flexDC knowMoreBtn mt courseDList">
                                    <label><span className='col6'>Duration:</span><span className='col6 tar'>100 Hrs</span></label>
                                    <label><span className='col6'>Mode of Training:</span> <span className='col6 tar'>Online</span></label>
                                    {/* <label><span className='col6'>Course Fee:</span><span className='col6 tar'>14999/-</span></label> */}
                                </div>                                                      
                                <div className="col12 flexCC">                            
                                <button className='btn0 knowMoreBtn mt c4b bn c7t br0' onClick={()=>{dispatch('popupForm')}}><span>Quick Enquire</span></button>
                                </div>
                            </div>                            
                        </div>
                    </li>
                    <li className='pb'>
                        <img src="../master-game-development-programmer.jpg" alt="" />
                        <div className="col12 pa prl2 c7t flexDCC">
                            <div className="col12 flex mb mt">
                                <h5 className='f3 mf c4t col12'>Master: Game Development Programmer</h5>
                            </div>
                            <p className='col12 sT'>
                            Master the art of developing a 3D game using the Unity Game Engine and assets.
                            </p>
                            <div className="col12 flexDC">      
                                <div className="col12 flexDC knowMoreBtn mt courseDList">
                                    <label><span className='col6'>Duration:</span><span className='col6 tar'>196 Hrs</span></label>
                                    <label><span className='col6'>Mode of Training:</span> <span className='col6 tar'>Online & Offline</span></label>
                                    {/* <label><span className='col6'>Online Fee:</span><span className='col6 tar'>28999/-</span></label> */}
                                    {/* <label><span className='col6'>Offline Fee:</span><span className='col6 tar'>48999/-</span></label> */}
                                </div>                                                      
                                <div className="col12 flexCC">                                
                                <button className='btn0 knowMoreBtn mt c4b bn c7t br0' onClick={()=>{dispatch('popupForm')}}><span>Quick Enquire</span></button>
                                </div>
                            </div>                            
                        </div>
                    </li>
                    <li className='pb'>
                        <img src="../professionalUnity.jpg" alt="" />
                        <div className="col12 pa prl2 c7t flexDCC">
                            <div className="col12 flex mb mt">
                                <h5 className='f3 mf c6t col12'>Professional: Unity 3D Developer</h5>
                            </div>
                            <p className='col12 sm4 md8 sT'>
                                The best course offering game development using Unity engine in detail with placement assistance
                            </p>
                            <div className="col12 flexDC">      
                                <div className="col12 flexDC knowMoreBtn mt courseDList">
                                    <label><span className='col6'>Duration:</span><span className='col6 tar'>290 Hrs</span></label>
                                    <label><span className='col6'>Mode of Training:</span> <span className='col6 tar'>Offline</span></label>
                                    {/* <label><span className='col6'>Course Fee:</span><span className='col6 tar'>99999/-</span></label> */}
                                </div>                                                      
                                <div className="col12 flexCC">                                
                                <button className='btn0 knowMoreBtn mt c4b bn c7t br0' onClick={()=>{dispatch('popupForm')}}><span>Quick Enquire</span></button>
                                </div>
                            </div>                            
                        </div>
                    </li>
                    {/*MOdification on 07-04-2022 @sibin*/}
                    <li className='pb'>
                        <img src="../p_d_m.png" alt="" />
                        <div className="col12 pa prl2 c7t flexDCC">
                            <div className="col12 flex mb mt">
                                <h5 className='f3 mf c4t col12'>Python Developer - Python -DJango -MongoDB </h5>
                            </div>
                            <p className='col12 sm4 md8 sT'>
                                Be a professional developer with our Python training program specialized in Django and MongoDB
                            </p>
                            <div className="col12 flexDC">      
                                <div className="col12 flexDC knowMoreBtn mt courseDList">
                                    <label><span className='col6'>Duration:</span><span className='col6 tar'>195 Hrs</span></label>
                                    <label><span className='col6'>Mode of Training:</span> <span className='col6 tar'>Offline</span></label>
                                    {/* <label><span className='col6'>Course Fee:</span><span className='col6 tar'>99999/-</span></label> */}
                                </div>                                                      
                                <div className="col12 flexCC">                                
                                <button className='btn0 knowMoreBtn mt c4b bn c7t br0' onClick={()=>{dispatch('popupForm')}}><span>Quick Enquire</span></button>
                                </div>
                            </div>                            
                        </div>
                    </li>
                    <li className='pb'>
                        <img src="../p_a_m.png" alt="" />
                        <div className="col12 pa prl2 c7t flexDCC">
                            <div className="col12 flex mb mt">
                                <h5 className='f3 mf c6t col12'>Full-Stack Developer - Python-AngularJS-MongoDB</h5>
                            </div>
                            <p className='col12 sm4 md8 sT'>
                            Be a full-stack developer with expert training specialized in Python and AngularJS and MongoDB.
                            </p>
                            <div className="col12 flexDC">      
                                <div className="col12 flexDC knowMoreBtn mt courseDList">
                                    <label><span className='col6'>Duration:</span><span className='col6 tar'>255 Hrs</span></label>
                                    <label><span className='col6'>Mode of Training:</span> <span className='col6 tar'>Offline</span></label>
                                    {/* <label><span className='col6'>Course Fee:</span><span className='col6 tar'>99999/-</span></label> */}
                                </div>                                                      
                                <div className="col12 flexCC">                                
                                <button className='btn0 knowMoreBtn mt c4b bn c7t br0' onClick={()=>{dispatch('popupForm')}}><span>Quick Enquire</span></button>
                                </div>
                            </div>                            
                        </div>
                    </li>
                    <li className='pb'>
                        <img src="../p_r_m.png" alt="" />
                        <div className="col12 pa prl2 c7t flexDCC">
                            <div className="col12 flex mb mt">
                                <h5 className='f3 mf c2t col12'>Full-Stack Developer - Python- ReactJS - MongoDB</h5>
                            </div>
                            <p className='col12 sm4 md8 sT'>
                            Be a full-stack developer with expert training specialized in Python and AngularJS and MongoDB   .
                            </p>
                            <div className="col12 flexDC">      
                                <div className="col12 flexDC knowMoreBtn mt courseDList">
                                    <label><span className='col6'>Duration:</span><span className='col6 tar'>255 Hrs</span></label>
                                    <label><span className='col6'>Mode of Training:</span> <span className='col6 tar'>Offline</span></label>
                                    {/* <label><span className='col6'>Course Fee:</span><span className='col6 tar'>99999/-</span></label> */}
                                </div>                                                      
                                <div className="col12 flexCC">                                
                                <button className='btn0 knowMoreBtn mt c4b bn c7t br0' onClick={()=>{dispatch('popupForm')}}><span>Quick Enquire</span></button>
                                </div>
                            </div>                            
                        </div>
                    </li>
                    <li className='pb'>
                        <img src="../Flutter.png" alt="" />
                        <div className="col12 pa prl2 c7t flexDCC">
                            <div className="col12 flex mb mt">
                                <h5 className='f3 mf c2t col12'>Flutter- Android App Development</h5>
                            </div>
                            <p className='col12 sm4 md8 sT'>
                                Learn Android app development using Flutter from experts in the industry.
                            </p>
                            <div className="col12 flexDC">      
                                <div className="col12 flexDC knowMoreBtn mt courseDList">
                                    <label><span className='col6'>Duration:</span><span className='col6 tar'>290 Hrs</span></label>
                                    <label><span className='col6'>Mode of Training:</span> <span className='col6 tar'>Offline</span></label>
                                    {/* <label><span className='col6'>Course Fee:</span><span className='col6 tar'>99999/-</span></label> */}
                                </div>                                                      
                                <div className="col12 flexCC">                                
                                <button className='btn0 knowMoreBtn mt c4b bn c7t br0' onClick={()=>{dispatch('popupForm')}}><span>Quick Enquire</span></button>
                                </div>
                            </div>                            
                        </div>
                    </li>
                    <li className='pb'>
                        <img src="../React.png" alt="" />
                        <div className="col12 pa prl2 c7t flexDCC">
                            <div className="col12 flex mb mt">
                                <h5 className='f3 mf c4t col12'>Angular JS Developer</h5>
                            </div>
                            <p className='col12 sm4 md8 sT'>
                                Learn AngularJS- the most in-demand front-end framework with the expert team.
                            </p>
                            <div className="col12 flexDC">      
                                <div className="col12 flexDC knowMoreBtn mt courseDList">
                                    <label><span className='col6'>Duration:</span><span className='col6 tar'>100 Hrs</span></label>
                                    <label><span className='col6'>Mode of Training:</span> <span className='col6 tar'>Offline</span></label>
                                    {/* <label><span className='col6'>Course Fee:</span><span className='col6 tar'>99999/-</span></label> */}
                                </div>                                                      
                                <div className="col12 flexCC">                                
                                <button className='btn0 knowMoreBtn mt c4b bn c7t br0' onClick={()=>{dispatch('popupForm')}}><span>Quick Enquire</span></button>
                                </div>
                            </div>                            
                        </div>
                    </li>
                    <li className='pb'>
                        <img src="../Angular.png" alt="" />
                        <div className="col12 pa prl2 c7t flexDCC">
                            <div className="col12 flex mb mt">
                                <h5 className='f3 mf c6t col12'>ReactJS Developer</h5>
                            </div>
                            <p className='col12 sm4 md8 sT'>
                            We offer an exclusive training program in ReactJS - one of the best front end frameworks.
                            </p>
                            <div className="col12 flexDC">      
                                <div className="col12 flexDC knowMoreBtn mt courseDList">
                                    <label><span className='col6'>Duration:</span><span className='col6 tar'>100 Hrs</span></label>
                                    <label><span className='col6'>Mode of Training:</span> <span className='col6 tar'>Offline</span></label>
                                    {/* <label><span className='col6'>Course Fee:</span><span className='col6 tar'>99999/-</span></label> */}
                                </div>                                                      
                                <div className="col12 flexCC">                                
                                <button className='btn0 knowMoreBtn mt c4b bn c7t br0' onClick={()=>{dispatch('popupForm')}}><span>Quick Enquire</span></button>
                                </div>
                            </div>                            
                        </div>
                    </li>
                    {/*Modification end @sibinthomas */}
                </ul>
            </div>                
        </div>
    )
}

export default Course
