import React,{useState, useRef} from 'react';
import Iframe from 'react-iframe'

// validation function
const regExEmail =/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
const regExWords = /^[a-zA-Z_ ]*$/;
const regExLengthChk = /^[a-zA-Z_ ]{3,35}$/;
const regExComments = /^[A-Za-z][a-z0-9_ .,-]{5,500}$/;
const regExMob = /^[\d-]{10,15}$/;

const isEmpty = value => value.trim() === "";

const loading = (
    <div className='loading'>
        <img src="./loading-gif.gif" alt="" />
    </div>
)


function ContactContent() {
    const [sucessTxt,setSucessTxt]= useState();
    const [errorTxt,setErrorTxt]= useState();
    const [loadState,setLoadState] = useState(false);

    const [contactValid, setContactValid] = useState({
        name: true,
        nameChck:true,
        nameLength:true,
        email:true,
        emailChk:true,
        mob:true,
        mobNum:true,
        course:true,
        message:true,
        messageLength:true,
    });      

    const nameField = useRef();
    const emailField = useRef();
    const mobField = useRef();
    const courseField = useRef();
    const messageField = useRef();

    const contactFormHandler = e => {

        e.preventDefault();

        const nameValue = nameField.current.value;
        const emailValue = emailField.current.value;
        const mobValue = mobField.current.value;
        const courseValue = courseField.current.value;
        const messageValue = messageField.current.value;

        const nameValid = !isEmpty(nameValue);
        const emailValid = !isEmpty(emailValue);
        const mobValid = !isEmpty(mobValue);
        const courseValid = !isEmpty(courseValue);
        const messageValid = !isEmpty(messageValue);

        setContactValid({
            ...contactValid,
            name: nameValid,
            email:emailValid,
            mob:mobValid,
            course:courseValid,
            message:messageValid            
        });

        const ContactFormValid = nameValid && emailValid && mobValid && courseValid && messageValid && contactValid.nameChck && contactValid.emailChk && contactValid.mobNum && contactValid.nameLength && contactValid.messageLength;

        if(!ContactFormValid){
            return
        }else{
            setLoadState(true);
            const formName = "contactForm";
            
            fetch(`https://bigboyschool.com/big_boy/project/api/message/sent?form=${formName}&name=${nameValue}&email=${emailValue}&mob=${mobValue}&course=${courseValue}&message=${messageValue}`,{
                method:'POST'                
            }).then((res) => res.json())
            .then((data) => {
                setSucessTxt(data.message);
                setLoadState(false);
            })
            .catch((err) => setErrorTxt(err));  
        }

        nameField.current.value = '';
        emailField.current.value = '';
        mobField.current.value = '';
        courseField.current.value = '';
        messageField.current.value = '';

    }
    const nameChangeValid = e =>{
        const Result = regExWords.test(e.target.value);
        const LengthChk = regExLengthChk.test(e.target.value);
        setContactValid({
            ...contactValid,
            name: true,  
            nameChck : Result,
            nameLength: LengthChk         
        })
    }
    const emailChangeValid = e =>{
        const Result = regExEmail.test(e.target.value);
        setContactValid({
            ...contactValid,
            email: true, 
            emailChk:Result           
        })
    }   
    const mobChangeValid = e =>{
        const Result = regExMob.test(e.target.value);
        setContactValid({
            ...contactValid,
            mob: true,
            mobNum:Result
        })
    }  
    const courseChangeValid = e =>{
        setContactValid({
            ...contactValid,
            course: true,            
        })
    } 
    const messageChangeValid = e =>{
        const Result = regExComments.test(e.target.value);
        setContactValid({
            ...contactValid,
            message: true, 
            messageLength:Result,           
        })
    }                 
    return (
        <div>
            <div className="col12 sm4 md8 flexCC pt4 pb4">
                <div className="wrapper">
                    <div className="col12 sm4 md8 flex sflexDC">
                        <div className="quickForm1 flexDLC col12">
                            <h4 className='bf mb c5t'>Send A Message</h4>
                            {loadState && loading}
                            {sucessTxt && <h5 className='mf c6t mb'>Successfully Submitted.Thank You!</h5>}
                            {errorTxt && <h5 className='mf c2t mb'>Sorry, Error occurred Please try again</h5>}                            
                            <form className='col12 sm4 md8 br rel' onSubmit={contactFormHandler}>   

                                {!contactValid.name && <p className='c7t c4b pl errorMsg'> 
                                <span className='mr'><i class="fa-solid fa-circle-exclamation"></i></span>                
                                Please Enter Your Name</p>}  

                                {!contactValid.nameChck && contactValid.name && <p className='c7t c4b pl errorMsg'> 
                                <span className='mr'><i class="fa-solid fa-circle-exclamation"></i></span>                
                                Please dont enter specail character</p>} 

                                {!contactValid.nameLength && contactValid.name && contactValid.nameChck && <p className='c7t c4b pl errorMsg'> 
                                <span className='mr'><i class="fa-solid fa-circle-exclamation"></i></span>                
                                Please lengthen this text to 3 character or less than 35</p>}                                 

                                <input type="text" placeholder='First Name' ref={nameField} onChange={nameChangeValid}/> 

                                {!contactValid.email && <p className='c7t c4b pl errorMsg'>
                                <span className='mr'><i class="fa-solid fa-circle-exclamation"></i></span>
                                Please Enter Your Email ID</p>} 

                                {!contactValid.emailChk && contactValid.email && <p className='c7t c4b pl errorMsg'>
                                <span className='mr'><i class="fa-solid fa-circle-exclamation"></i></span>
                                Invalid Email ID</p>}   

                                <input type="email" placeholder='Email ID'  ref={emailField} onChange={emailChangeValid}/>
 

                                {!contactValid.mob && <p className='c7t c4b pl errorMsg'>
                                <span className='mr'><i class="fa-solid fa-circle-exclamation"></i></span>
                                Please Enter Your Mobile Number</p>} 

                                {!contactValid.mobNum && contactValid.mob && <p className='c7t c4b pl errorMsg'>
                                <span className='mr'><i class="fa-solid fa-circle-exclamation"></i></span>
                                Invalid Mobile Number</p>} 

                                <input type="number" placeholder='Mobile Number' ref={mobField} onChange={mobChangeValid}  onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}/>

                                {!contactValid.course && <p className='c7t c4b pl errorMsg'> 
                                <span className='mr'><i class="fa-solid fa-circle-exclamation"></i></span>                
                                Please Select Your Courses</p>}  
                                <select className='c5t' ref={courseField} onChange={courseChangeValid}>
                                <option value=" ">Select Course</option>
                                <option value="Junior: Unity Game Developer">Junior: Unity Game Developer</option>
                                <option value="Master: Game Development Programmer">Master: Game Development Programmer</option>
                                <option value="Professional: Unity 3D Developer">Professional: Unity 3D Developer</option>
                                <option value="Python Developer - Python -DJango -MongoDB ">Python Developer - Python -DJango -MongoDB </option>
                                <option value="Full-Stack Developer - Python- ReactJS - MongoDB">Full-Stack Developer - Python- ReactJS - MongoDB</option>
                                <option value="Full-Stack Developer - Python-AngularJS-MongoDB">Full-Stack Developer - Python-AngularJS-MongoDB</option>
                                <option value="Flutter- Android App Development">Flutter- Android App Development</option>
                                <option value="Angular JS Developer">Angular JS Developer</option>
                                <option value="ReactJS Developer">ReactJS Developer</option>
                                </select>

                                {!contactValid.message && <p className='c7t c4b pl errorMsg'> 
                                <span className='mr'><i class="fa-solid fa-circle-exclamation"></i></span>                
                                Please Enter Your Message</p>}  

                                {!contactValid.messageLength && contactValid.message &&<p className='c7t c4b pl errorMsg'> 
                                <span className='mr'><i class="fa-solid fa-circle-exclamation"></i></span>                
                                Message should start with alphabets min 5 and max 500 character only!</p>} 

                                <textarea className='pa' cols="30" rows="30" ref={messageField} placeholder='Your Message Here...' onChange={messageChangeValid}></textarea>

                                <button class="btn3 br0 bn c6b c7t flexCC">                        
                                    <span><i class="fa fa-paper-plane" aria-hidden="true"></i> Send</span>
                                </button>
                            </form>
                        </div>
                        <div className="col12 sm4 md8 ma2 flex map">                            
                        <Iframe className='bn' url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3929.1637285300444!2d76.30421835063267!3d10.003331092813832!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080d1a73dbe2e3%3A0xb80e7eca4274f71!2sPrasanthi%20Ln%2C%20Sonia%20Nagar%2C%20Edappally%2C%20Ernakulam%2C%20Kerala%20682025!5e0!3m2!1sen!2sin!4v1644488799453!5m2!1sen!2sin"
                                width="100%"
                            />
                        </div>
                    </div> 
                    <div className="col12 sm4 md8 pt2 pb flexCC c8b pa2 br mt2 sflexDC">
                        <h4 className='col5 sm4 f3 mb pa'>Interested in Unity Game Development - Get in Touch With Us!</h4>
                        <div className='col7 sm4 taj mb'>
                            <h5 className='mf pa'>Do you love games? How about creating some exciting games? Learn Unity and get into the world of game development that offers numerous opportunities.
                                <h4 className='flexRC c1t pa sflexCC'>
                                    <i class="fab fa-facebook"></i>
                                    <i class="fab fa-twitter"></i>
                                    <i class="fab fa-youtube"></i>                                
                                </h4>
                            </h5>
                        </div>
                    </div>                   
                </div>
            </div>
        </div>
    )
}

export default ContactContent
