import React,{useEffect} from 'react';
import NavBar from './NavBar'
import Footer from './Footer';

function Layout(props) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return <div>
    <NavBar/>
        <div>
            {props.children}
        </div>
    <Footer/>
  </div>;
}

export default Layout;
