import React from 'react'
import { useContext } from 'react';
import { Context } from '../Provider';
import { NavLink, Link } from 'react-router-dom';

function NavBar() {
   
    const [state, dispatch] = useContext(Context);
    
    return (
        <div className='col12 sm4 md8 flexCC c1b ptb'>
            <div className="wrapper flexSBC  sflexW navWrap">
                <div className="logo col3 sm2 md8">
                    <Link to="/"><img src="../BigBoy-Logo.svg" alt="" /></Link>
                </div>               
                    <div className='handburge c7t'><label for="menuCheck"><i class="fa-solid fa-bars"></i></label></div>    
                    <input type="checkbox" id='menuCheck' />            
                <ul className='col5 sm2 md8 flexSAC c7t navLink'>
                    <div className='handburgeClose flexR col12 sm4 md8 tar'><label for="menuCheck"><i class="fa-solid fa-xmark"></i></label></div>
                    <li><NavLink to="/" className='ml c5t'>Home</NavLink></li>
                    <li><NavLink to="/about" className='ml c5t'>About</NavLink></li>
                    <li><NavLink to="/courses" className='ml c5t'>Courses</NavLink></li>
                    <li><NavLink to="/gallery" className='ml c5t'>Gallery</NavLink></li>
                    <li><NavLink to="/contact" className='ml c5t'>Contact</NavLink></li>                                     
                </ul>
                <div className="flexDCR col3 sm4 md8 c7t topContact">
                    <h6 className='mf flexCC c7t'><i class="fas fa-mobile-alt"></i> <a className='c7t ml' href="tel:8138066211">+91-8138066211</a></h6>
                    <h6 className='mf flexCC c7t'><i class="fas fa-envelope"></i> <a className='c7t ml' href="mailto:info@bigboyschool.com">info@bigboyschool.com</a></h6>
                </div>
            </div>
        </div>
    )
}

export default NavBar
