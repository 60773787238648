import React, { useContext} from 'react';
import { Context } from '../Provider';
import Layout from '../Component/Layout';
import ContentGallery from '../Component/ContentGallery';
import QuickForm from '../Component/QuickForm';
import WhyChoose from '../Component/WhyChoose';


function Gallery() {
    const [state, dispatch] = useContext(Context);
    return (
        <Layout>
           <ContentGallery/>
           <WhyChoose/>
           { state.quickform ? <QuickForm/>:'' }           
        </Layout>
    )
}

export default Gallery
