import React, { useContext} from 'react';
import { Context } from '../Provider';
import { Link } from 'react-router-dom';

function Footer() {  
    const [state, dispatch] = useContext(Context); 
    return (
        <div>
            <div className="col12 sm4 md8 subFooter flexCC"> 
                <div className="wrapper flexC ptb sflexDCC">
                    <div className="col12 sm4 md8 mb">
                        <h4 className='c7t f3 mf'>Enquire Now, Get More Info!</h4>
                        <h6 className='c7t mf f1'>Get the Curriculum, Fee Details and More</h6>
                    </div>
                    <div className="col12 flexRC sflexDCC mt">
                        <button className='btn3 br0 bn' onClick={()=>{dispatch('popupForm')}}><i class="fa fa-address-book mr" aria-hidden="true"></i><span> ENQUIRE NOW</span></button>
                        <button className='btn3 br0 bn c4b c7t' onClick={()=>{dispatch('popupForm')}}><i class="fa fa-envelope mr" aria-hidden="true"></i><span> EMAIL REQUEST</span></button>
                    </div>                
                </div>
            </div>
            <div className="col12 sm4 md8 flexCC c9b">
                <div className="wrapper">
                    <ul className='col12 sm4 md8 flex sflexDCC footer pt4 pb4'>
                        <li className='col4 sm4 md8 pa flexDC'>
                            <h5 className='mf f3 c7t mb2'>Learn from the Best</h5>
                            <p className='sT c10t'>BigBoy School of Gaming Pvt. Ltd - No.1 choice for learning Unity game development courses</p>
                            <div className='flex c1t col12 mt socialIcon'>
                                <a href=""><i class="fab fa-facebook"></i></a>
                                <a href=""><i class="fab fa-twitter"></i></a>
                                <a href=""><i class="fab fa-youtube"></i></a>                                
                            </div>
                        </li>
                        <li className='col4 sm4 md8  pa'>
                            <h5 className='mf f3 c7t mb2'>Quick Links</h5>
                            <ul className='footerNav flex'>
                                <div className="col12">
                                    <li><Link to="/about" className='c7t'>About Us</Link></li>
                                    <li><Link to="/courses" className='c7t'>Courses</Link></li>                                  
                                </div>
                                <div className="col12">
                                    <li><Link to="/gallery" className='c7t'>Gallery</Link></li>
                                    <li><Link to="/contact" className='c7t'>Contact</Link></li>
                                </div>

                            </ul>
                        </li>
                        <li className='col4 sm4 md8 pa'>
                            <h5 className='mf f3 c7t mb2'>Contact Us</h5>
                            <address>
                                <p className='sT c10t'>
                                Big Boy School of Gaming Pvt Ltd, Prasanthi Ln, Sonia Nagar, Palarivattom, Ernakulam, Kerala 682025                                   
                                </p>
                                <div className="clas12 flexDC mt">

                                    <div className='mr c7t flex mb'>
                                        <i class="fas fa-mobile-alt"></i>
                                        <label> 
                                        <a className='c7t ml' href="tel:8138066211">+91-8138066211</a>
                                        <a className='c7t ml' href="tel:8138066311">+91-8138066311</a>
                                        </label>
                                    </div>

                                    <a href="mail:info@bigboyschool.com" className='c7t flex'>
                                        <i class="fas fa-envelope"></i>
                                        <a href="mailto:info@bigboyschool.com" className='ml c7t'>info@bigboyschool.com</a>
                                    </a>
                                </div>
                            </address>
                        </li>
                    </ul>
                </div>                    
            </div>
        </div>
    )
}
export default Footer
