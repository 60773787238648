import React from 'react'
import { Link } from 'react-router-dom'


function StudentsGames() {
    return (
        <div className='col12 sm4 md8 stundGame sticky flexCC'>
            <div className='wrapper flexDCC'>
                <h2 className='c7t f3 mf tac mb2 col12 sm4 md8'>Our Students Portfolio Section</h2>
                <h5 className='c7t f3 mf taj mb2 col12  sm4 md8 tac'>Find the works done by our students here</h5>
                <div className="col12 flexCC sflexDCC sm4 md8 studentsPro">
                    <Link to="/gallery" className='ml btn2 c4b bn br0 c7t mb'><span>Students Works</span></Link>
                    <Link to="/contact" className='ml btn2 c2b bn br0 c7t mb'><span>Contact Us</span></Link>
                </div>
            </div>
        </div>
    )
}

export default StudentsGames
