import React from 'react'

function WhyChoose() {
    return (
        <div>
            <div className="col12 sm4 md8 whyChoose flexC pt8 pb4">
                <div className="wrapper">
                    <h3 className='TitleHead mb4 mf f3 col12 tac c3t'>Why Choose BigBoy</h3>
                    <ul className='col12 sm4 md8 flex sflexDC mt4'>
                        <li className='col4 sm4 md8 pa'>
                            <div className="col12 sm4 md8 flex">
                                <div className="col3 sm4 md2">
                                    <img src="../education.png" alt="" />
                                </div>
                                <div className="col9 sm4 md6">
                                    <h5>Certification</h5>
                                    <p>Certification to ensure your expertise as a Unity developer</p>
                                </div>
                            </div>
                            <div className="col12 sm4 md8 flex">
                                <div className="col3 sm4 md2">
                                    <img src="../expert-trainer.png" alt="" />
                                </div>
                                <div className="col9 sm4 md6">
                                    <h5>Expert Trainers</h5>
                                    <p>An expert faculty team with the deep industry knowledge</p>
                                </div>
                            </div>
                            <div className="col12 sm4 md8 flex">
                                <div className="col3 sm4 md2">
                                    <img src="../industry-expert.png" alt="" />
                                </div>
                                <div className="col9 sm4 md6">
                                    <h5>Industry Experience </h5>
                                    <p>Offers the best industry experience to our students.</p>
                                </div>
                            </div>                                                        
                        </li>
                        <li className='col4  sm4 md8 whyWe flexCC rel'>
                            <img src="../bigCloud.png" alt="" className='abs bigClod' />
                            <img src="../whywe.png" alt="" />
                        </li>
                        <li className='col4   sm4 md8 pa'>
                            <div className="col12 sm4 md8 flex">
                                <div className="col3 sm4 md2">
                                    <img src="../placement-ensurance.png" alt="" />
                                </div>
                                <div className="col9 sm4 md6">
                                    <h5>Placement Assistance</h5>
                                    <p>We help students in finding the best opportunities.</p>
                                </div>
                            </div>
                            <div className="col12 sm4 md8 flex">
                                <div className="col3 sm4 md2">
                                    <img src="../career-guidance.png" alt="" />
                                </div>
                                <div className="col9 sm4 md6">
                                    <h5>Career Guidance</h5>
                                    <p>Provide insights on building a successful career.</p>
                                </div>
                            </div>
                            <div className="col12 sm4 md8 flex">
                                <div className="col3 sm4 md2">
                                    <img src="../intership.png" alt="" />
                                </div>
                                <div className="col9 sm4 md6">
                                    <h5>Internship & Projects</h5>
                                    <p>Opportunities to hone your skills with exciting projects.</p>
                                </div>
                            </div>                                                        
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default WhyChoose
