import React, { useContext} from 'react';
import { Context } from '../Provider';
import Layout from '../Component/Layout';
import Banner from '../Component/Banner';
import Course from '../Component/Course';
import Welcome from '../Component/Welcome';
import StudentsGames from '../Component/StudentsGames';
import Recruite from '../Component/Recruite';
import WhyChoose from '../Component/WhyChoose'
import Testimonial from '../Component/Testimonial'
import QuickForm from '../Component/QuickForm';
import PrivacyPolicy from '../Component/PrivacyPolicy';



function Home() {
  const [state, dispatch] = useContext(Context);
    return (
        <Layout>
          <Banner/>     
          <Course/>
          <Welcome/>
          <StudentsGames/>
          <Recruite/>
          <WhyChoose/>
          <Testimonial/>
          { state.quickform ? <QuickForm/>:'' }           
        </Layout>
    )
}

export default Home
