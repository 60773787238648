import React from 'react'

function BreadCrubs(props) {
    return (
        <div>
            <div className="col12 sm4 md8 breadcrumb flexDCC">                
                <h3 className='c7t f3 mb2'>{props.title}</h3>
            </div>
        </div>
    )
}

export default BreadCrubs
