import React,{useEffect} from 'react'

function Testimonial() {
    const sliders = document.querySelectorAll(".testimoCanvas li");

    
    return (
        <div>
            <div className="col12 sm4 md8 flexC pt8 pb8 c3b">
                <div className="wrapper">
                    <h3 className='TitleHead mb4 mf f3 col12 tac c7t'>What Our Students Say</h3>
                    <ul className='col12 sm4 md8 testimo mt4'>
                        <div className="testimoCanvas">
                            <li className="col6 sm4 md4 c7b br pa2">
                                <h4 className='c8t'><i class="fas fa-quote-left"></i></h4>
                                <p className='mb'>Highly skilled and knowledgeable teaching staff. Good infrastructure and excellent placement options. BigBoy school provides vast opportunities to every student by informing them about various job openings and guiding students to crack the interview.</p>
                                <h6 className='f1'>Adithya</h6>
                                <h6 className='xT mf'>SENSATIONS SOLUTIONS</h6>
                            </li>
                            <li className="col6 sm4 md4 c7b br pa2">
                                <h4 className='c8t'><i class="fas fa-quote-left"></i></h4>
                                <p className='mb'>Good training and placement center. I would like to thank my faculty and placement coordinator for helping me get exposure to one of the most reputed IT companies. BigBoy school helped me to gain knowledge in software development and establish myself in the IT industry.</p>
                                <h6 className='f1'>Angel</h6>
                                <h6 className='xT mf'>Aricent</h6>
                            </li>                                                       
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Testimonial
