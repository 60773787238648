import React,{useContext,useRef,useState} from 'react'
import { Context } from '../Provider';


const loading = (
    <div className='loading'>
        <img src="./loading-gif.gif" alt="" />
    </div>
)

function QuickForm() {
    const [state, dispatch] = useContext(Context); 
    const [sucessTxt,setSucessTxt]= useState();
    const [errorTxt,setErrorTxt]= useState();
    const [loadState,setLoadState] = useState(false);

    const [validate,setValidate] = useState({
        name:true,
        nameChck:true,
        nameLength:true,
        email:true,
        emailChk:true,
        mob:true,        
        mobNum:true,
        course:true
    });

    const nameInput = useRef();
    const emailInput = useRef();
    const mobInput = useRef();
    const courseInput = useRef();

    // validation function
    const regExEmail =/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    const regExWords = /^[a-zA-Z_ ]*$/;
    const regExLengthChk = /^[a-zA-Z_ ]{3,35}$/;
    const regExMob = /^[\d-]{10,15}$/;

    const isEmpty = value => value.trim() === '';

    const nameEnterValue = e => {
        const Result = regExWords.test(e.target.value);
        const LengthChk = regExLengthChk.test(e.target.value);
        setValidate({
                ...validate,
                name:true,
                nameChck : Result,
                nameLength: LengthChk
        });
    }

    const emailEnterValue = e => {
        const Result = regExEmail.test(e.target.value);
        setValidate({
                ...validate,
                email:true,
                emailChk : Result
        });
    }  
    
    const mobEnterValue = e => {
        const Result = regExMob.test(e.target.value);
        setValidate({
                ...validate,
                mob:true,  
                mobNum : Result
        });
    } 
    
    const courseEnterValue = e => {
        const Result = !isEmpty(e.target.value);
        setValidate({
                ...validate,
                course : Result
        });
    }    
    
    
    //Form Button click event lister section 
    const sendFormData = (e)=>{

        e.preventDefault();

        const nameValue = nameInput.current.value;
        const emailValue = emailInput.current.value;
        const mobValue = mobInput.current.value;
        const courseValue = courseInput.current.value;          
         
        const nameValid = !isEmpty(nameValue);    
        const emailValid = !isEmpty(emailValue);
        const mobValid = !isEmpty(mobValue);
        const courseValid = !isEmpty(courseValue);

        setValidate({
            ...validate,
            name:nameValid,
            email:emailValid,
            mob:mobValid,
            course:courseValid
        });

        const formValidated = nameValid && emailValid && mobValid && courseValid && validate.nameChck && validate.emailChk && validate.mobNum && validate.nameLength;       

        if(!formValidated){
            return
        }else{
            setLoadState(true);
            const formName = "quickForm";
            
            fetch(`https://bigboyschool.com/big_boy/project/api/message/sent?form=${formName}&name=${nameValue}&email=${emailValue}&mob=${mobValue}&course=${courseValue}`,{
                method:'POST'                
            }).then((res) => res.json())
            .then((data) => {
                setSucessTxt(data.message);
                setLoadState(false);
            })
            .catch((err) => setErrorTxt(err));  
        }      

        nameInput.current.value = '';
        emailInput.current.value = '';
        mobInput.current.value = '';
        courseInput.current.value = '';
    }

    return (
        <div>
            <div className="quickForm flexCC" id='quick'>               
                <form className='col6 sm4 md5 c7b pa2 br ds1 rel' onSubmit={sendFormData}>
                    {sucessTxt && <h5 className='mf c6t mb'>Successfully Submitted.Thank You!</h5>}
                    {errorTxt && <h5 className='mf c2t mb'>Sorry, Error occurred Please try again</h5>}   
                    <div className="formCloseBtn flexCC" onClick={()=>{dispatch('popupFormClose')}}>X</div>
                    {loadState && loading}
                    <h5 className='mf mb c7t'>Quick Contact Form</h5>
                    
                    {/* name validation */}

                    {!validate.name && <p className='c7t c4b pl errorMsg'> 
                    <span className='mr'><i class="fa-solid fa-circle-exclamation"></i></span>                
                    Please Enter Your Name</p>}  

                    {!validate.nameChck && validate.name && <p className='c7t c4b pl errorMsg'> 
                    <span className='mr'><i class="fa-solid fa-circle-exclamation"></i></span>                
                    Your Name shouldn't contain special character</p>}  

                    {!validate.nameLength && validate.name && validate.nameChck && <p className='c7t c4b pl errorMsg'> 
                    <span className='mr'><i class="fa-solid fa-circle-exclamation"></i></span>                
                    Please lengthen this text to 3 character or less than 35</p>}                                         

                    <input type="text" placeholder='Your Name'  ref={nameInput} onChange={nameEnterValue} />  

                    {/* email validation */}

                    {!validate.email && <p className='c7t c4b pl errorMsg'>
                    <span className='mr'><i class="fa-solid fa-circle-exclamation"></i></span>
                    Please Enter Your Email ID</p>} 

                    {!validate.emailChk && validate.email && <p className='c7t c4b pl errorMsg'>
                    <span className='mr'><i class="fa-solid fa-circle-exclamation"></i></span>
                    Invalid Email ID</p>}                     

                    <input type="text" placeholder='Email ID' ref={emailInput} onChange={emailEnterValue} />

                    {/* mob validation */}

                    {!validate.mob && <p className='c7t c4b pl errorMsg'>
                    <span className='mr'><i class="fa-solid fa-circle-exclamation"></i></span>
                    Please Enter Your Mobile Number</p>} 

                    {!validate.mobNum && validate.mob && <p className='c7t c4b pl errorMsg'>
                    <span className='mr'><i class="fa-solid fa-circle-exclamation"></i></span>
                    Invalid Mobile Number</p>}  

                    <input type="number" placeholder='Mobile Number' ref={mobInput} onChange={mobEnterValue} onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}/>
                    
                    {/* course validation */}

                    {!validate.course && <p className='c7t c4b pl errorMsg'>
                    <span className='mr'><i class="fa-solid fa-circle-exclamation"></i></span>
                    Please Select Your Course</p>} 
                    <select className='c5t' ref={courseInput} onChange={courseEnterValue}>
                    <option value=" ">Select Course</option>
                                <option value="Junior: Unity Game Developer">Junior: Unity Game Developer</option>
                                <option value="Master: Game Development Programmer">Master: Game Development Programmer</option>
                                <option value="Professional: Unity 3D Developer">Professional: Unity 3D Developer</option>
                                <option value="Python Developer - Python -DJango -MongoDB ">Python Developer - Python -DJango -MongoDB </option>
                                <option value="Full-Stack Developer - Python- ReactJS - MongoDB">Full-Stack Developer - Python- ReactJS - MongoDB</option>
                                <option value="Full-Stack Developer - Python-AngularJS-MongoDB">Full-Stack Developer - Python-AngularJS-MongoDB</option>
                                <option value="Flutter- Android App Development">Flutter- Android App Development</option>
                                <option value="Angular JS Developer">Angular JS Developer</option>
                                <option value="ReactJS Developer">ReactJS Developer</option>
                    </select>
                   
                    <button class="btn3 br0 bn c6b c7t flexCC">                        
                        <span><i class="fa fa-paper-plane" aria-hidden="true"></i> Send</span>
                    </button>
                </form>
            </div>
        </div>
    )
}

export default QuickForm
