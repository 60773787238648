import React, { useContext} from 'react';
import { Context } from '../Provider';
import Layout from '../Component/Layout';
import BreadCrubs from '../Component/BreadCrubs';
import Content from '../Component/Content';
import QuickForm from '../Component/QuickForm';
import WhyChoose from '../Component/WhyChoose';

function About() {
    const [state, dispatch] = useContext(Context);
    return (
        <Layout>
           <BreadCrubs title="About Us"/>
           <Content/>
           <WhyChoose/>
           { state.quickform ? <QuickForm/>:'' }
        </Layout>
    )
}

export default About
