import React from 'react'

function ContentGallery() {
    return (
        <div>
            <div className="col12 sm4 md8 flexCC pt4 pb4">
                <div className="wrapper">
                    <ul className="Megagallery col12 sm4 md8">
                        <li class="big"><img src="../office1.jpg" alt="" /></li>
                        <li class="vertical"><img src="../office2.jpg" alt="" /></li>
                        <li class="big"><img src="../office3.jpg" alt="" /></li>
                        <li class="big"><img src="../office4.jpg" alt="" /></li>
                        <li class="vertical"><img src="../office9.jpg" alt="" /></li>
                        <li class="big"><img src="../office5.jpg" alt="" /></li>
                        <li class="big"><img src="../office6.jpg" alt="" /></li>
                        <li class="big"><img src="../office7.jpg" alt="" /></li>
                        <li class="big"><img src="../office8.jpg" alt="" /></li>                 
                    </ul>
                    <div className="col12 sm4 md8 pt2 pb flexCC c8b pa2 br mt2 mb2 sflexDCC">
                        <h4 className='col5 f3 mb pa sm4'>Interested in Unity Game Development - Get in Touch With Us!</h4>
                        <div className='col7 sm4 taj mb'>
                            <h5 className='mf pa'>Do you love games? How about creating some exciting games? Learn Unity and get into the world of game development that offers numerous opportunities.
                                <h4 className='flexRC c1t pa sflexCC'>
                                    <i class="fab fa-facebook"></i>
                                    <i class="fab fa-twitter"></i>
                                    <i class="fab fa-youtube"></i>                                
                                </h4>                            
                            </h5>
                        </div>
                    </div>
                    <ul className="Megagallery col12 sm4 md8">
                        <li class="big"><img src="../game03.jpg" alt="" /></li>
                        <li class="vertical"><img src="../game05.jpg" alt="" /></li>
                        <li class="big"><img src="../game09.jpg" alt="" /></li>
                        <li class="big"><img src="../game06.jpg" alt="" /></li>
                        <li class="big"><img src="../game08.jpg" alt="" /></li>
                        <li class="big"><img src="../game04.jpg" alt="" /></li>                       
                        <li class="big"><img src="../game07.jpg" alt="" /></li>
                        <li class="vertical"><img src="../game01.jpg" alt="" /></li>
                        <li class="vertical"><img src="../game02.jpg" alt="" /></li>
                        <li class="vertical"><img src="../game01.jpg" alt="" /></li>
                    </ul>                                       
                </div>
            </div>            
        </div>
    )
}

export default ContentGallery
